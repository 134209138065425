import { ref,toRefs, reactive, computed } from 'vue';
import { useBreakpoint } from "vue-composable";

const __initDialogBox = () => {
    return {
        show: false,
        title:'訊息',
        message:"",
        loading_mode:false,
        is_loading:false,

        icon:"",
        persistent:false,
        btnConfirmShow:true,
        btnConfirmText:"我知道了",
        btnConfirmEvent:null,
        btnCancelShow:false,
        btnCancelText:"取消",
        btnCancelEvent:null
       
    }
}
const layoutState = reactive({
    mainMenuActive: false,
});
const isAsyncDone = ref(true)
const dialogBox = reactive(__initDialogBox());
// const toolbar = reactive({
//     title:'',
//     showBack:false,
//     styleReverse:false,
//     backTo:{name:'home'}
// })

export function useLayout() {
    const breakpoint = useBreakpoint({ L: 1024, XS: 320,S:400,M:720 });

    const sizeFolder = computed(()=>{
        if(breakpoint.current.value == 'XS') return '1024X512';//'320X160'
        if(breakpoint.current.value == 'S') return '1024X512'; //'640X320'
        if(breakpoint.current.value == 'M') return '1024X512';//'768X384'
        return '1024X512'
    })
    const onMenuToggle = () => {
        layoutState.mainMenuActive = !layoutState.mainMenuActive;
    };
    const asyncDone = (value) => {
        isAsyncDone.value = value
    };
  
    const openDialogBox = (options) => {
        Object.assign(dialogBox,__initDialogBox())
        Object.assign(dialogBox,options)
        dialogBox.show = true

    }
    const closeDialogBox = (options) => {
        dialogBox.show = false
        Object.assign(dialogBox,__initDialogBox())

    }
    const callDialogConfirmEvent = async (options) => {
        if(dialogBox.btnConfirmEvent !== null){
            dialogBox.is_loading = true
            await dialogBox.btnConfirmEvent()

        }
        dialogBox.is_loading = false
        closeDialogBox()

    }
    const callDialogCancelEvent = async (options) => {
        if(dialogBox.btnCancelEvent !== null){
            dialogBox.is_loading = true
            await dialogBox.btnCancelEvent()

        }
        dialogBox.is_loading = false
        closeDialogBox()

    }
   

    // const setToolBar = (options) => {
      
    //     Object.assign(toolbar,options)
        
    // }
    return { 
        layoutState: toRefs(layoutState),
        dialogBox,isAsyncDone,sizeFolder,
        // toolbar:toRefs(toolbar),
        // setToolBar,
        openDialogBox,closeDialogBox,
        onMenuToggle,asyncDone,
        callDialogConfirmEvent,
        callDialogCancelEvent
    };
}

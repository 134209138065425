import { toRefs, ref,onMounted,reactive, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router'
import { useHead,useSeoMeta } from '@unhead/vue'

const title = `${process.env.VUE_APP_PRODUCT_NAME}`
const defaultMeta = {
    title:title,
    desc:`${process.env.VUE_APP_PRODUCT_NAME}，報名、金流、簽到、對帳一次搞定，數位管理好便利！`,
    image:"https://life.goodder.co/goodderlife.png",
    url:"https://life.goodder.co",
    type:"article"
}
const seoHeaderBar = reactive({
    title:"",
    logo:""
    //@/assets/logo-d-w.png
})
export function useSeo(conf) {
    const route = useRoute()

    function initDefaultSEO(){
    
        let initMeta = [] 
        if(route.meta.seo){
            if(conf && conf.meta){
                initMeta = [
                    {dataVueMeta:"1", property:"og:title" ,content:conf.meta.title?conf.meta.title:defaultMeta.title},
                    {dataVueMeta:"1", property:"og:description" ,content:conf.meta.description?conf.meta.description:defaultMeta.description},
                    {dataVueMeta:"1", property:"og:image" ,content:conf.meta.image?conf.meta.image:defaultMeta.image},
                    {dataVueMeta:"1", property:"og:url" ,content:conf.meta.url?conf.meta.url:defaultMeta.url},
                    {dataVueMeta:"1", property:"og:type" ,content:conf.meta.type?conf.meta.type:defaultMeta.type}
                ]
            }
    
            useHead({
                title: `${route.meta.seo.title} | ${title}`,
                meta: initMeta,
            })

        }
    }
    function setSEOHeaderBar(value){
        Object.assign(seoHeaderBar,value)
    }
    return { 
        seoHeaderBar:toRefs(seoHeaderBar),
        setSEOHeaderBar,initDefaultSEO
    };
}
